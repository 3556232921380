import React from 'react';
import { NavbarCN , UserAgreementCN } from '../../components';

function UserAgreeCN() {
  return (
    <>
    <NavbarCN />
    <UserAgreementCN />
    </>
  );
}

export default UserAgreeCN;