import React from 'react';
import { Navbar , UserAgreement } from '../../components';

function UserAgree() {
  return (
    <>
    <Navbar />
    <UserAgreement />
    </>
  );
}

export default UserAgree;