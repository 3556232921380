import React from 'react';
import { NavbarCN , PrivacyPolicyCN } from '../../components';

function Privacy() {
  return (
    <>
    <NavbarCN />
    <PrivacyPolicyCN />
    </>
  );
}

export default Privacy;