import React from 'react';
import { Navbar , PrivacyPolicy } from '../../components';

function Privacy() {
  return (
    <>
    <Navbar />
    <PrivacyPolicy />
    </>
  );
}

export default Privacy;